.tabela{
  font-size: 18px;
  padding: 16px;
  margin: 0 auto;
  min-width: 90%;
  border-collapse: collapse;


  td,th{
    padding: 12px 0;
    max-width: 300px;
  }


  thead tr th{border-bottom: 2px solid black}
  thead th:last-of-type{
    pointer-events: none;
  }

  tbody tr{
      border-bottom: 1px solid black;
      text-align: center;
  }

  .options{
    display: flex;
    justify-content: space-between;
  }

  .ban,
  .izmijeni,
  .obrisi{
    padding: 4px 12px;
    border-radius: 12px;
    color: white;
  }
  .ban{
    background: red;
  }

  .izmijeni{
    background: #296bd5;
  }

  .obrisi{
    background: darkred;
  }
}

