.zaposleni-novi{
  margin: 16px;
  max-width: 50%;
  font-size: 16px;
  &--edit{
    max-width: 100%;
  }

  &__rola{
    margin-right: 32px;
  }

  &__rola-container{
    font-size: 16px;
    margin-bottom: 12px  ;
    span{ margin-right: 12px}
    div{
      display: inline-block;
    }
  }

  &__add{
    padding: 4px 16px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.53);
    display: inline-block;
  }
}
