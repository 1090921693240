.threeSmallOneBigOneMed{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;

    .fixed-cardList-container{
        width: 295px;
        @media only screen and (min-width: 768px) and (max-width: 1100px) {
            width: 235px ;
        }

        .small-card{
            padding-top: 4px;
            padding-bottom: 2px;

            &__txt-container{
                padding-left: 12px;
            }
        }
    }

    .big-card{
        &:last-of-type{
            &:after{
                content: none;
            }
            @media only screen and (min-width: 768px) and (max-width: 1100px) {
                width: 167px ;
            }
        }
    }
}
