.komentari{
    text-align: center;
    padding-bottom: 48px;


    &__tekst-container{
        display: inline-block;
        position: absolute;
        left: 64px;
        input{
            min-width: 150px;
        }
    }
    &__header{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        h2{
            display: inline-block;
        }
    }
    @media only screen and (max-width: 1400px) {
        h2{
            font-size: 16px;
        }
    }


    .selected-user{
        background: #ffcc00;
        margin-left: 12px;
        padding: 12px;
    }

    .username{
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }

    table tr td:first-of-type{
        text-align: left;
    }

    table tr td:last-of-type{
        width: 130px;
    }

    table tr td{
        max-width: 400px;
    }

    table tr td:nth-of-type(5){
        width: 130px;
    }

    .user-status{
        padding: 4px 12px;
        background: #8BC34A;
        color: white;
        border-radius: 12px;
        margin-right: 12px;
        &--banned{
            background: red;
            color: white;
        }
    }
    .opcije{
        display: flex;
        flex-direction: column;
        a{
            margin: 4px;
        }
    }
}
