.anketa-edit {
  width: 768px;
  height: auto;
  text-align: left;

  .inline-txt {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input {
      flex: 1;
    }

    .votC {
      position: absolute;
      right: 90px;
    }
  }
}

.anketa-modal-preview {
  width: 600px;
}
