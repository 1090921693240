* {
    margin: 0;
    border: 0;
    box-sizing: border-box;
    font-family: Arial, serif !important;
}

*:focus {
    outline: none;
}

hr {
    border: 1px solid rgba(0, 0, 0, 0.48);
    margin-bottom: 12px;
    margin-top: 12px;
}

body {
    overflow-x: hidden;
}
body,
input,
button {
    font: 14px 'Roboto', sans-serif;
}
input,
textarea {
    border: 1px solid #00000052;
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
button {
    cursor: pointer;
    background: none;
    &:hover {
        text-decoration: underline;
    }
}

.inline-txt {
    display: block;
    span {
        margin-right: 12px;
    }
    margin-bottom: 12px;
    &--kolumna {
        display: none;
    }
}

.error {
    font-weight: bold;
    color: red;
    margin-left: 6px;
}

.loader-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    div {
        margin-left: 8px;
    }
}

.my-toast .Toastify__toast {
    background: #ffffff;
    border: 2px solid #ffcc00;
    border-radius: 10px;
}

.my-toast .Toastify__toast-body {
    color: black;
    font-weight: bold;
}

.my-toast .Toastify__toast.Toastify__toast--error {
    background: #e74c3c;
    border: none;

    .Toastify__toast-body {
        color: white;
        font-weight: normal;
    }
}
p,
span {
    font-size: 16px;
}

.btn-close {
    padding: 4px 12px;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    margin: 4px;
    &:hover {
        background: whitesmoke;
        text-decoration: underline;
    }
}

.btn-save {
    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.44);
    border-radius: 4px;
    margin: 4px;

    &:hover {
        background: whitesmoke;
        text-decoration: underline;
    }

    &--green{
        background: #66BB6A;
        color: white;
        border: none;
        font-weight: bold;
        &:hover{
            background: #3aba40;
        }
    }

    &--red{
        background: red;
        color: white;
        border: none;
        font-weight: bold;
        &:hover{
            background: red;
        }
    }

    &--selected {
        background: #ffcc00;
        color: black;
        font-weight: bold;
        border: none;
        &:hover {
            background: #ffcc00;
        }
    }
    &--right-absolute{
        position: absolute;
        right: 64px;
    }

    &--down-32{
        margin-top: 48px;
    }
}

@mixin default-btn {
    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.44);
    border-radius: 4px;
    margin: 4px;
    &:hover {
        background: whitesmoke;
        text-decoration: underline;
    }
}
select {
    border-radius: 4px;
    background: whitesmoke;
    padding: 4px 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.add-btn {
    background: whitesmoke;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
}

.flex {
    display: flex;
}

.navigation-step1 {
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding-left: 32px;

    @media only screen and (max-width: 1400px) {
        width: 80%;
    }

    a {
        text-align: center;
        flex-basis: 20%;
        color: black;
        @include default-btn;
        &:hover {
            text-decoration: none;
        }
    }
}

.flex-start {
    justify-content: flex-start;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12px;
    li {
        padding: 12px;
        list-style-type: none;
    }
    li.active {
        background: black;
        color: white;
    }
}


.square,
.rectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;
    color: black;
    &:hover {
        border: 2px solid green;
        cursor: pointer;
    }

    &--selected {
        border: 4px solid green;
        font-size: 30px;
        font-weight: bold;
        color: green;
        &:before {
            content: '☑';
            font-size: 30px;
        }
    }
}

.square {
    width: 150px;
    height: 150px;
}
.rectangle {
    &--small {
        width: 150px;
        height: 30px;
    }
    &--large {
        width: 180px;
        height: 100px;
    }

    &--medium {
        width: 180px;
        height: 50px;
    }
}
.wireframeSelected {
    border: 4px solid green !important;
    font-size: 30px!important;
    font-weight: bold!important;
    color: green!important;
    &:before {
        content: '☑';
        font-size: 30px;
    }
}


.wireframeSelected-video{
    border: 4px solid green !important;
    &:before {
        top: 40%;
        width: 50px;
        height: 50px;
        font-size: 72px;
        left: 45%;
        color: green;
        position: absolute;
        content: '☑';
    }
}


.btn-delete{
    background: none;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    background: lightgrey;
    padding: 10px;
}

.tabela-header-left{
    thead {
        tr:first-of-type{
            text-align: left;
        }
    }
}

.big-card__img{
    min-width: 100px;
}

.w-100 {
    width: 100%;
}
