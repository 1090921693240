.custom-modal{
  text-align: center;
  font-size: 16px;
  margin-top: 10%;

  >div{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__info{
    max-width: 60%;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &__btn-da{
      padding: 6px 12px;
      border: 1px solid black;
     border-radius: 4px;
  }

  &__btn-ne{
    margin-top: 12px;
    background: none;
  }
}
