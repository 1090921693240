.sport-container{
    &__top{
      display: flex;
      flex-wrap: wrap;
      height: 205px;
      overflow: hidden;

      .fixed-cardList-container{
        flex: 1;
        margin-left: 12px;

        .small-card__txt-container{
          max-width: 220px;
          height: 25px;
        }
      }
    }

  &__bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .small-card{
      height: 80px;
    }
    .small-card__txt-container{
      height: 60px;
    }

    .big-card{
      width: 300px;
      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 180px ;
      }
      &__content{
        height: 120px;
      }

    }

    .fixed-cardList-container{
      width: 400px;

      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 350px ;
      }


      .small-card{
        padding-top: 2px;
        padding-bottom: 2px;
      }
      .small-card__txt-container{
        padding-left: 12px;
      }


    }
  }
}
