.magazin-container{

  margin-top: 48px;

  .one-big-two-medium-container{
    .big-card__content{
      height: 115px;
    }
  }

  &__bottom{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 250px;
    margin-top: 12px;

    .fixed-cardList-container{
      width: 400px;

      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 357px ;
      }

      .small-card{
        padding-top: 4px;
        padding-bottom: 2px;

        &__txt-container{
          padding-left: 12px;
        }
      }
    }

    .big-card{
      &__content{
        height: 128px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 174px ;
      }
    }
  }
}
