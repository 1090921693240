.push-notifikacije {

  &__results-container {
    margin-top: 32px;
  }

  &__results {
    margin-top: 12px;
    max-width: 50%;

    .item {
      margin-bottom: 12px;
      padding: 12px;
      word-break: break-word;
      display: flex;
      justify-content: space-between;
      align-items: center;


      p {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      button {
        background: black;
        color: white;
        padding: 4px 12px;
        min-width: 88px;
        margin-left: 12px;
        border-radius: 4px;
      }

      &--selected {
        background: #ffcc00;
      }
    }
  }
}
