.leaderboard-card{
  display: flex;
  flex-wrap: wrap;
  height: 203px;
  position: relative;
  width: 600px;

  @media only screen and (min-width: 768px) and (max-width: 1100px) {
    width: 528px ;
  }

  &__txt-container{
    width: 206px;
    background: ghostwhite;
    height: 100%;
    color: black;
    box-sizing: border-box;
    position: relative;
    display: flex;
    margin: 4px;
    border: 1px solid red;
    align-items: center;
    justify-content: center;
    &:hover{
      border: 2px solid darkgreen;
    }

    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      width: 173px;
    }


    &--black{
      color: white;
      background: black;
    }

    &--red{
      color: white;
      background: darkred;
    }

    &:hover{
      font-weight: bold;
    }

    .iconText{
      bottom: 6px;
      right: 10px;
      position: absolute;
    }
  }

  &__naslov{
    font-family: 'Arial MT', serif;
    font-size: 18px;
  }

  &__image{
    flex: 1;
    width: 300px;
    height: 207px;
    object-fit: cover;
    margin: 4px;
  }

}
