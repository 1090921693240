
.txt-left-padding{
  .small-card__txt-container{
    padding-left: 12px;
  }
}
.small-card{
  display: block;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  width: 97%;
  border-bottom: 1px solid grey;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 10px;




  &:last-of-type{
    border-bottom: none;
  }
  &__txt-container{
 //   max-width: 150px;
    padding-right: 16px;
    margin: 4px;
    border: 1px solid red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      border: 2px solid darkgreen;
    }


    div{
      max-height: 73px;
      overflow: hidden;
    }

    &--m-txt{
      padding-left: 12px;

    }

    &--m-txt-black{
      background: black;
      padding-left: 12px;
      color: white;

      .bold{
        color: darkgoldenrod;
      }
    }
  }

  &--with-image{

    display: flex;
    flex-direction: row;
    align-items: stretch;

  }

  &__img{
    object-fit: cover;
    margin: 4px;
  }

  &__img--186{
    width: 186px;
    height: 141px;
    margin-right: 22px;
  }

  &__img--95{
    width: 95px;
    height: 70px;

    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      width: 90px;
      height: 80px;
    }

  }

  &__img--113{
    width: 113px;
    height: 70px;


    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      width: 90px;
      height: 60px;
    }

  }

  &__img--101{
    width: 101px;
    height: 66px;
  }
}
