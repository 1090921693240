.nova-rubrika{
  padding: 32px;

  h4,div,p{
    margin-top: 14px;
    margin-bottom: 14px;
  }

  &__marker{
    margin-left: 12px;
  }
}
