.sign-in{
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 16px;

  &__logo{
    margin: 0 auto;
  }

  &__container{
    border: 2px solid black;
    border-radius: 4px;
    padding: 32px;
  }

  &__input{
    width: 80%;
    height: 50px;
    border: 2px solid black;
    border-radius: 4px;
    margin-bottom: 12px;
    padding-left: 12px;
  }

  &__h4{
    padding-left: 68px;
    margin-top: 24px;
    margin-bottom: 12px;
    text-align: left;
    span{
      color: red;
    }
  }

  &__login{
    border: 2px solid black;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 4px;
    &:hover{
      background: whitesmoke;
    }
  }
}
