.opcije{

  text-align: center;
  h1{
    text-align: center;
    margin-bottom: 32px;
  }
  h3{
    margin-bottom: 16px;
  }
  &__container{
    display: flex;
    flex-wrap: wrap;
    padding: 5%;
  }

  &__item{
    margin-bottom: 16px;
    span{
      margin-right: 12px;
    }
    &__img{
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__notifikacije,
  &__profil{
    flex:1;
    margin: 16px;
  }
}
