.new-tekst{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 32px;
  position: relative;

  .cke_editable p
  {
    margin-bottom:14px;
  }

  &__tip-sadrzaja{
    background: #ffcc00;
    padding: 3px 12px;
  }

  &__autori-container{
    position: relative;
  }

  &__autori-dropdown{
    min-width: 200px;
    min-height: 150px;
    z-index: 10;
    &:empty{
      display: none;
    }
    display: block;
    position: absolute;
    background: #ffffff;
    top: 70px;
    padding: 12px;
    box-shadow: 0 12px 13px 7px #00000038;
    div{
      border-bottom: 1px solid rgba(0, 0, 0, 0.21);
      padding-top: 4px;
      padding-bottom: 4px;
      &:hover{
        cursor: pointer;
        border-bottom: 1px solid black;
      }
    }

    .close{
      position: absolute;
      top: -2px;
      font-size: 22px;
      right: 10px;
      &:hover{ cursor: pointer}
    }
  }

  input{
    padding: 12px;
  }

  &__left{
    width: 780px;
    margin-right: 16px;

    @media only screen and (max-width: 1400px) {
      width: 620px;

    }
  }
  &__right{
    flex:1;
  }

  &__left{
    input,textarea{
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__tags{

    input{
      width: 300px;
    }

    &__container{
      padding: 12px;
      min-height: 80px;
      background: whitesmoke;
      border: 1px solid #00000052;
      border-radius: 4px;
      margin-top: 4px;
      margin-bottom: 4px;

    }
  }

  &__tag{
    padding: 4px 0 4px 12px;
    border-radius: 4px;
    background: white;
    width: auto;
    display: inline-block;
    margin: 4px;
    border: 1px solid #00000029;

    &__close{
      margin-left: 12px;
      background: #ffcc00;
      padding: 5px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &:hover{
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .live-info-container{
    max-height: 600px;
    overflow: scroll;
    overflow-x: hidden;
    border: 2px solid rgba(0, 0, 0, 0.11);
    .blogRow{
      display: flex;
      margin-top: 8px;
      border-bottom: 1px solid black;
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
      .timestamp{
        margin-right: 12px;
      }
    }
  }
}
