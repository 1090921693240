.pozicioniraj {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .auto-position {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ffcc00;
    border-radius: 12px;
    padding: 12px;
    margin-right: 24px;

    > div {
      display: flex;
      margin-bottom: 4px;
      align-items: center;

      input {
        width: 24px;
        height: 24px;
      }
    }

    p {
      color: gray;
    }
  }

  .najnovije-vijesti {
    border-top: 3px SOLID #ffa600;
  }

  .wireframe,
  .najnovije-vijesti {
    flex: 1;
  }

  .wireframe {
    width: 850px;
    margin: 0 auto;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1.2;
    padding-bottom: 128px;

    .wire-nav {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    .example::-webkit-scrollbar {
      display: none;
    }

    &__options {
      position: absolute;
      top: 100px;
      left: 208px;
      width: 150px;
      display: flex;
      flex-direction: column;

      > button {
        &:first-of-type {
          margin-bottom: 32px;
          width: 80px;

        }

        &:nth-of-type(2) {
          width: 80px;
        }
      }

      > a > button {
        &:before {
          content: 'Repozicioniraj';
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
        }
      }
    }
  }

  .najnovije-vijesti {
    overflow-y: scroll;
    padding-top: 24px;
    padding-bottom: 128px;

    .arhiv-filters__top {
      justify-content: center;
    }

    .arhiv-filters__footer {
      width: 100%;
      text-align: center;
    }

    .checks {
      input {
        width: 25px;
        height: 25px;
      }
    }
  }
}
