.main-page {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    &__sidebar {
        background: whitesmoke;
        width: 200px;
        height: auto;
        text-align: center;
        padding-top: 16px;
        &__avatar {
            border-radius: 50%;
            border: 2px solid black;
            box-shadow: 3px -2px 4px 2px #ffffff;
        }
    }

    &__track {
        width: 100vw;
        text-align: left;
        background: #ffcc00;
        display: flex;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 12px;
        &--uredi-sadrzaj{
            width: 100%;
        }
    }

    &__author {
        width: 200px;
        text-align: center;
    }

    &__track-options {
        flex: 1;
        display: flex;
        align-items: center;

        a {
            margin-left: 12px;
            margin-right: 12px;
            background: whitesmoke;
            color: black;
            border-radius: 4px;
            padding: 4px 12px;
            transition: all 0.2s;
        }

        a.active {
            border-top: 4px solid black;
            background: white;
        }
    }

    &__side-nav {
        display: flex;
        flex-direction: column;

        a:before {
            width: 30px;
            background-color: #d0d0d0;
            content: '';
            position: absolute;
            height: 34px;
            top: -1px;
            left: -28px;
        }

        a.active {
            background: #ffcc00;
            border: none;
            font-weight: bold;
            transition: background-color;
            color: black;
            &:before {
                width: 30px;
                background-color: black;
                content: '';
                position: absolute;
                height: 32px;
                top: 0;
                left: -27px;
            }

        }

        a {
            position: relative;
            border-radius: 4px;
            margin: 12px;
            color: black;
            transition: all 0.2s;
            background: ghostwhite;
            &:hover {
                text-decoration: underline;
                &:before {
                    background-color: black;
                }
            }

            border: 1px solid #00000026;
            padding: 8px;
        }
    }

    &__opcije {
        width: 100vw;
        text-align: end;
        padding-right: 12px;
        &__btn {
            padding: 4px 12px;
            border: 1px solid #00000026;
            border-radius: 4px;
            margin: 4px;
            &:hover {
                background: whitesmoke;
                text-decoration: underline;
            }
        }
    }

    &__content {
        flex: 1;
        margin-top: 220px;
        &--uredi-sadrzaj{
            margin-top: 0;
        }
    }
}
