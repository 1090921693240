.leaderboard-with-big{
    height: 203px;
  display: flex;

    .big-card{
        &:after{
            content: none;
        }

        @media only screen and (min-width: 768px) and (max-width: 1100px) {
           padding-top: 0;
            border-top: none;
            width: 173px;
        }
    }
}
