.fostav-container{
margin-top: 32px;
  .two-big-cards{
    height: 300px;
    .big-card{
      height: 300px;
      max-height: 300px;
      span{
        height: auto;
      }
      img{
        height: 150px;
      }
      .content{
        height: 130px;
      }
    }
  }

  &__bottom{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 250px;
    margin-top: 18px;


    .big-card{
      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 171px ;
      }
      &__content{
        height: 120px;
      }
    }

    .fixed-cardList-container{
      width: 400px;


      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 357px ;
      }

      .small-card{
        padding-top: 4px;
        padding-bottom: 2px;
        .small-card__txt-container{
          padding-left: 12px;
        }
      }
    }
  }
}
