.arhiv-results{
    .tabela{
        .opcije{
            width: 100px;
            margin: 0 auto;
            position: relative;
            &:hover{
                text-decoration: underline;
            }
            &__dropdown{
                display: none;
                background: white;
                position: absolute;
                z-index: 10;
                box-shadow: 1px 1px 4px 0px black;
                width: 160px;
                left: -25px;
                span{
                    padding: 4px;
                    display: block;
                    width: 100%;
                    margin: 5px auto 0;
                    &:hover{
                        background: #ffcc00;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }


            }
            input{
                left: 0;
                top: 0;
                position: absolute;
                width:100%;
                height: 100%;
                opacity: 0;
            }

            input[type=checkbox]:hover{
                cursor: pointer;
            }
            input[type=checkbox]:checked~span{
                background: red;
            }
            input[type=checkbox]:checked+div,
            {
                display: block;
            }
        }

        .autori-column{

            span{
                display: block;
            }
        }

        tbody tr td:first-of-type{
            text-align: left;
        }
    }

}
