.bnews {
  display: flex;
  flex-direction: column;
  width: 600px;


  &__slider {
    display: flex;
    background: #333333;
    div {
      color: white;
    }

    .square {
      flex: 1;
      margin: 12px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;

    .square {
      flex: 1;
      margin: 12px;
    }
    &__large {
      display: flex;
      flex-direction: row;

      .square {
        margin: 4px;
      }

      &__img {
        flex: 1;
        margin: 4px;
        height: 150px;
        object-fit: cover;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .rectangle {
        margin: 4px;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: ghostwhite;
    margin-bottom: 22px;

    .big-card {
      margin-right: 8px;

      &__content {
        height: 113px;
      }
    }

    &__group {
      flex: 1;
      margin: 8px;

      .rectangle {
        margin-bottom: 4px;

        &--medium {
          height: 31%;
        }
      }

    }
  }
}
