.zaposleni-analitika{
  width: 100%;
  padding: 24px;

  &__details{
    padding-left: 64px;
    padding-top: 24px;
  }

  &__stats{
    display: flex;
    flex-direction: column;

    &__header{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: start;
      margin-top: 32px;
      margin-bottom: 8px;
      span{
        display: block;
        flex-basis: 20%;
      }
    }
  }

  &__stat-values{
    display: flex;
    flex-direction: row;
    justify-content: start;
    background: whitesmoke;
    border: 1px solid black;
    padding: 16px;
    span,div{
      display: block;
      flex-basis: 20%;
      font-weight: bold;
    }

    &__group{
      display: flex !important;
      flex-wrap: wrap;
      span:not(:first-of-type){
        margin-bottom: 4px;
        margin-left: 12px;
      }
    }
  }
  &__header{
    padding-left: 64px;
    padding-right: 64px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid black;
    width: 90%;
  }
}

