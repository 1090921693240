.galerija-upload-preview{
  width: 100%;
}

#galery-preview-modal{
  z-index: 25000;

  .image-gallery-slide img {
    width: 100%;
    height: 500px;
  }
}
.galerija-upload {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    object-fit: contain;
  }

  &__container {
    position: relative;
    margin: 12px;

    span {
      font-size: 24px;
      position: absolute;
      top: -8px;
      right: -12px;
      background: #ff1818;
      color: white;
      border-radius: 100%;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      &:hover {
        color: black;
        cursor: pointer;
      }
    }
  }
}
