.new-photo{

  &__quick-preview{
    position: relative;
    text-align: center;
    img{
      object-fit: contain;
    }
    span{
      position: absolute;
      right: 130px;
      top: -2px;
      background: red;
      border-radius: 50%;
      display: flex;
      align-items: center;
      width: 25px;
      height: 25px;
      font-size: 15px;
      justify-content: center;
      color: white;
      &:hover{
        color: black;
        cursor: pointer;
      }
    }
  }
  &__preview{
    position: relative;
    img{
      object-fit: contain;
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      background: red;
      border-radius: 50%;
    }
  }
}
