.two-big-cards{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

   .big-card{
     width: 413px;
     height: 300px;
     border-top: none;

     @media only screen and (min-width: 768px) and (max-width: 1100px) {
       width: 357px ;
     }


     &:nth-of-type(2){
       &:after{
         content: none;
       }
     }

     &__img{
       width: 100%;
     }
   }
}