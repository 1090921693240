.one-big-two-medium-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .big-card {
    height: 300px;

    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      width: 171px ;
    }


    &__content {

    }

    &:first-of-type {
      width: 400px;

      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 357px ;
      }

      .big-card__content {
        padding-left: 12px;
      }
    }


  }


}
