.svijet-container{

    margin-top: 24px;
    .leaderboard-with-big.space-between{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .big-card{
            width: 222px;
            height: 200px;
            &__content{
                height: 75px;
            }
        }
    }
    &__bottom{
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 250px;
        .big-card{
            &__content{
                height: 100px;
            }
        }
    }
}
