.arhiv-filters{
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__top{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 24px;


        input,
        select{
            margin: 12px;
            min-width: 100px;
        }
        >div{
            position: relative;
            margin-bottom: 12px;
            &:before{
                position: absolute;
                top: -12px;
                left: 12px;
            }
        }
    }

    &__kword{
        &:before{
            content: 'Ključna riječ';
        }
    }


    &__tema{
        &:before{
            content: 'Tema';
        }
    }
    &__rubrika{
        &:before{
            content: 'Rubrika';
        }
    }

    &__podrubrika{
        &:before{
            content: 'Podrubrika';
        }
    }

    &__status{
        &:before{
            content: 'Status';
        }
        .disabled{
            pointer-events: none;
            opacity: .2;
        }
    }
    &__naslov{
        &:before{
            content: 'Naslov';
        }
    }

    &__author{
        &:before{
            content: 'Autor';
        }
        h2{display: none}
        input{
            margin: 0;
            width: 200px !important;
        }
        .new-tekst__tags{
            margin-top: 12px !important;
        }
        .new-tekst__tags__container{
            min-height: 40px;
            display: flex;
            flex-direction: column;
        }
        .new-tekst__autori-dropdown{
            margin-top: -29px;
        }
        .new-tekst__tag{
            max-width: max-content;
        }
    }

    &__datum-od{
        &:before{
            content: 'od datuma';
        }
    }
    &__datum-do{
        &:before{
            content: 'do datuma';
        }
    }
}

