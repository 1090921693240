.arhiv{
  padding: 12px;
  margin-bottom: 100px;
  &__header{
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title{
      font-weight: bold;
      font-size: 24px;
    }
  }

  &__subtitle{
      font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span:last-of-type{
      flex: 1;
      text-align: center;
      margin-left: -10%;
    }
    &__type{
      background: #ffcc00;
      padding: 4px 12px;
    }
  }

  .tabela{
    padding: 0;
    width: 100%;
  }
}
