.big-card {
    width: 192px;
    display: block;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    border-top: 1px solid black;
    padding-top: 8px;
    position: relative;
    height: 248px;
    max-height: 250px;

    &:last-of-type {
        &:after {
            content: none;
        }
    }

    &--no-border-top {
        border-top: none;
    }


    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 150px;
        border: 1px solid red;
      &:hover{
        border: 2px solid darkgreen;
      }

        &--166 {
        }

        &--213 {
        }
    }

    &__img-container {
        height: 120px;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &--120 {
            height: 120px;
        }

        &--115 {
            height: 115px;
        }


        &--166 {
            height: 166px;
        }
    }
    &:hover {
        font-weight: bold;
        background: ghostwhite;
    }

    &:after {
        position: absolute;
        width: 1px;
        background: ghostwhite;
        height: 97%;
        content: '';
        bottom: 0;
        right: -7px;
    }
}
