.istrazivali-smo-container{

  &__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .leaderboard-card{
      width: 600px;

      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 528px;
      }
    }

    .big-card{
      width: 230px;
      height: 210px;
      &__content{
        height: 80px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 184px;
        border-top: none;
        padding-top: 0;
      }
    }
  }

}
