
.infos-container {


  .big-card {
    width: 230px;

    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      width: 183px;
    }

  }
}

.infos-container {
  margin-top: 32px;

  &__section-top {
    height: 203px;
    overflow: hidden;

    .big-card {
      &__content {
        height: 70px;
      }
    }
  }

  &__section-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 250px;

    .big-card {
      width: 230px;
      margin-top: -204px;
      height: 210px;

      &__content {
        height: 75px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 183px;
      }

    }

    .two-big-three-small {
      width: 600px;

      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        width: 528px;
      }

      .fixed-cardList-container {
        margin-top: 12px;
      }

      .big-card,
      .fixed-cardList-container {
        width: 192px;

        @media only screen and (min-width: 768px) and (max-width: 1100px) {
          width: 167px;
        }

        .small-card {
          height: 30%;

          div {
            height: 100%;
          }
        }
      }

      .big-card {
        margin-top: 12px !important;

      }

    }
  }

}
