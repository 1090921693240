.banners-head {
  display: flex;
  justify-content: right;
  position: relative;

  button {
    margin-right: 80px;
    height: 33px;
    background: #ffcc00;
    color: black;
    padding: 6px 12px;
    font-weight: bold;
    border-radius: 4px;
  }
}

.banners {
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__status {
    display: flex;
    align-items: center;
    margin-right: 83px;
    margin-top: 12px;
    input {
      margin-right: 8px;
    }
  }
}
